import { User } from '../../../../../types/User';

export interface ConversationType {
  conversationTypeSeq: string;
  typeName: string;
  description?: string;
}

export interface Conversation {
  conversationSeq: string;
  conversationType: ConversationType;
  createdByUserSeq: string;
  createdAt: string;
  topic: string;
  messages: ConversationMessage[];
  participants: ConversationParticipant[];
  unreadCount: number;
  messageCount: number;
  lastMessageSeq: string | null;
  isOpen: boolean;
  isActive: boolean;
}

export interface Attachment {
  attachmentId: string;
  fileName: string;
  fileSize: number;
  fileType: string;
  url?: string;
  previewUrl?: string;
}

export interface ConversationMessage {
  messageSeq: string;
  senderUserSeq: string;
  senderName: string;
  content: string;
  sentAt: string;
  readByCount: number;
  readBy: Array<{
    userSeq: string;
    userName: string;
    personFirstName: string;
    personLastName: string;
    readTimestamp: string;
  }>;
  attachments?: Attachment[];
}
export interface ConversationParticipant {
  userSeq: string;
  userName: string;
  personFirstName: string;
  personLastName: string;
  joinedAt: string;
}

export const ConversationTypes = {
  Feedback: 'c39113b3-8fd0-47c4-bb9b-299f86e3fc94',
  New_Account_Request: 'fa7d4037-2a3f-49c9-9c04-392306c80b8e',
  Error_Report: '3af6f76f-0f7b-4560-81c9-5b96e5cf3a10',
  Chat: '80925c64-7809-494d-88ed-be2a31c50a12',
};

export const shouldMaskNameAsSupportAgent = (
  m: ConversationMessage,
  conversationTypeSeq: string,
  user: User
) => {
  const isSender = m.senderUserSeq.toLowerCase() === user.userSeq.toLowerCase();
  // const isUserSystemAdmin = user.roleCheck(['SYSTEMS-ADMIN']);
  // const isNormalChat = conversationTypeSeq.toLowerCase() === ConversationTypes.Chat.toLowerCase();

  // if (isUserSystemAdmin) return false;
  // if (isNormalChat) return false;
  // if (isSender) return false;

  return (
    !user.roleCheck(['SYSTEMS-ADMIN']) &&
    [
      ConversationTypes.Feedback,
      ConversationTypes.Error_Report,
      ConversationTypes.New_Account_Request,
    ].includes(conversationTypeSeq) &&
    !isSender
  );
};
