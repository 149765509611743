import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogContent, useMediaQuery, useTheme, Zoom } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, useEffect } from 'react';
import { useAuth } from '../../../../../../utils/auth/AuthService';
import ConversationLayout from '../../UserFeedbackView';
import { Conversation, ConversationTypes } from '../conversation.types';
import { useConversation } from '../ConversationsContext';
import { NewConversation } from './NewConversationDialog';
const { REACT_APP_API_URL } = process.env;

interface CreateConversationDialogProps {
  open: boolean;
  onClose: () => void;
  onConversationCreated: (conversation: Conversation) => void;
}

// const ACCEPTED_FILE_TYPES = {
//   'image/*': ['.png', '.jpg', '.jpeg', '.gif', '.bmp', '.webp'],
//   'application/pdf': ['.pdf'],
// };

// const ALLOWED_FILE_TYPES_TEXT = 'Allowed file types: PNG, JPG, JPEG, GIF, BMP, WEBP, PDF';
// const MAX_TOTAL_SIZE = 10 * 1024 * 1024; // 10MB in bytes

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});

export function NewFeedbackDialog({
  open,
  onClose,
  onConversationCreated,
}: CreateConversationDialogProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { user } = useAuth();
  const { fetchUserConversations } = useConversation();
  useEffect(() => {
    if (open) {
      fetchUserConversations({
        userSeq: user.userSeq,
        conversationTypeSeqs: [
          ConversationTypes.Chat,
          ConversationTypes.Feedback,
          ConversationTypes.New_Account_Request,
        ],
      });
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          return;
        }
        onClose();
      }}
      maxWidth='xl'
      fullScreen={isMobile}
      TransitionComponent={isMobile ? Transition : undefined}
      fullWidth
      disableEscapeKeyDown
      sx={{ p: 0, m: 0 }}
    >
      <DialogContent sx={{ p: 0, m: 0, bgcolor: user.isDarkMode ? 'black' : 'white' }}>
        <ConversationLayout
          kind='user'
          title='Messages'
          DefaultComponent={<NewConversation onClose={onClose} open={open} isDialog={false} />}
          CloseButtonComponent={
            <Button startIcon={<Close fontSize='small' />} onClick={onClose}>
              Close
            </Button>
          }
        />
      </DialogContent>
    </Dialog>
  );
}
