import { Close, Send } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useConversation } from './ConversationsContext';
import { ConversationTypes } from './conversation.types';

interface MessageInputProps {
  preventEscapeKeyClosing?: boolean;
  isConversationClosed?: boolean;
}

export function MessageInput({
  preventEscapeKeyClosing = false,
  isConversationClosed = false,
}: MessageInputProps) {
  const { handleSendMessage, selectedConversation, emitTypingIndicator } = useConversation();
  const [message, setMessage] = useState('');
  const [attachments, setAttachments] = useState<File[]>([]);
  const [previews, setPreviews] = useState<{ file: File; url: string }[]>([]);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isReopenDialogOpen, setIsReopenDialogOpen] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isSending, setIsSending] = useState(false);

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
    if (selectedConversation) {
      emitTypingIndicator(selectedConversation.conversationSeq);
    }
  };

  const handleSubmit = async (e: React.MouseEvent) => {
    if (isConversationClosed) {
      setIsConfirmDialogOpen(false);
      setIsReopenDialogOpen(true);
      return;
    }
    await sendMessage(e);
  };

  const handleSendClick = () => {
    setIsConfirmDialogOpen(true);
  };

  const processFiles = useCallback(
    (files: File[]) => {
      const newFiles = files.filter(file => !attachments.some(a => a.name === file.name));

      newFiles.forEach(file => {
        const previewUrl = URL.createObjectURL(file);
        setPreviews(prev => [...prev, { file, url: previewUrl }]);
      });

      setAttachments(prev => [...prev, ...newFiles]);
    },
    [attachments]
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      processFiles(acceptedFiles);
    },
    [processFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  const removeAttachment = (fileToRemove: File) => {
    setAttachments(prev => prev.filter(file => file !== fileToRemove));
    setPreviews(prev => {
      const filteredPreviews = prev.filter(preview => preview.file !== fileToRemove);
      const removedPreview = prev.find(preview => preview.file === fileToRemove);
      if (removedPreview) {
        URL.revokeObjectURL(removedPreview.url);
      }
      return filteredPreviews;
    });
  };

  const handleAttachmentClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files);
      processFiles(selectedFiles);
    }
    // Reset the input value so the same file can be selected again
    e.target.value = '';
  };

  // Separate dropzone for the TextField to handle file input click
  const { getRootProps: getTextFieldDropProps, getInputProps: getTextFieldInputProps } =
    useDropzone({
      onDrop,
      noClick: false,
    });

  const renderAttachmentPreviews = (previewsToRender: typeof previews) => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
      {previewsToRender.map(({ file, url }) => (
        <Box
          key={file.name}
          sx={{
            position: 'relative',
            width: 100,
            height: 100,
            borderRadius: 1,
            overflow: 'hidden',
            border: '1px solid',
            borderColor: 'divider',
          }}
        >
          {file.type.startsWith('image/') ? (
            <img
              src={url}
              alt={file.name}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          ) : (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'action.hover',
              }}
            >
              <Typography variant='caption' sx={{ p: 1, textAlign: 'center' }}>
                {file.name}
              </Typography>
            </Box>
          )}
          <IconButton
            size='small'
            onClick={e => {
              e.stopPropagation();
              removeAttachment(file);
            }}
            sx={{
              position: 'absolute',
              top: 4,
              right: 4,
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'action.hover',
              },
            }}
          >
            <Close fontSize='small' />
          </IconButton>
        </Box>
      ))}
    </Box>
  );

  const sendMessage = async (e?: React.MouseEvent) => {
    try {
      setIsSending(true);
      handleSendMessage(selectedConversation?.conversationSeq!, message, attachments);
      setMessage('');
      previews.forEach(preview => URL.revokeObjectURL(preview.url));
      setPreviews([]);
      setAttachments([]);
      setIsConfirmDialogOpen(false);
      setIsReopenDialogOpen(false);
    } finally {
      setIsSending(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (message.trim().length === 0) return;
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (selectedConversation?.conversationType.conversationTypeSeq === ConversationTypes.Chat) {
        sendMessage();
      } else {
        handleSendClick();
      }
    }
  };
  return (
    <>
      <Box
        {...getRootProps()}
        className='message-input-container'
        sx={{
          position: 'sticky',
          bottom: 0,
          left: 0,
          right: 0,
          bgcolor: 'background.paper',
          borderTop: '1px solid',
          borderColor: 'divider',
          px: 2,
          py: 1,
          zIndex: 10,
        }}
      >
        <input {...getInputProps()} />

        {/* Hidden file input */}
        <input
          type='file'
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileInput}
          multiple
        />

        {/* Drag overlay */}
        {isDragActive && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              bgcolor: 'action.hover',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 20,
              borderRadius: 1,
            }}
          >
            <Typography variant='h6' color='primary'>
              Drop files here
            </Typography>
          </Box>
        )}

        {/* Attachment Previews */}
        {previews.length > 0 && <Box sx={{ mb: 2 }}>{renderAttachmentPreviews(previews)}</Box>}

        {/* Message Input Area */}
        <Grid container spacing={2} alignItems='center'>
          {/* <Grid item>
            <Tooltip title='Attach files'>
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  handleAttachmentClick();
                }}
                size='small'
                sx={{
                  color: 'text.secondary',
                  '&:hover': {
                    color: 'primary.main',
                  },
                }}
              >
                <AttachFile />
              </IconButton>
            </Tooltip>
          </Grid> */}
          <Grid item xs>
            <Box {...getTextFieldDropProps()} sx={{ width: '100%' }}>
              <input {...getTextFieldInputProps()} />
              <TextField
                placeholder='Type a message...'
                fullWidth
                multiline
                value={message}
                onChange={handleMessageChange}
                onKeyDown={handleKeyDown}
                onClick={e => e.stopPropagation()}
                disabled={isSending}
                sx={{
                  '& .MuiInputBase-root': {
                    py: 1,
                    px: 2,
                    bgcolor: theme => (theme.palette.mode === 'dark' ? 'grey.900' : 'grey.100'),
                    borderRadius: 2,
                    '&:hover': {
                      bgcolor: theme => (theme.palette.mode === 'dark' ? 'grey.800' : 'grey.200'),
                    },
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleSendClick}
              disabled={(!message.trim() && attachments.length === 0) || isSending}
              sx={{
                bgcolor: theme => theme.palette.primary.main,
                color: 'white',
                '&:hover': {
                  bgcolor: theme => theme.palette.primary.dark,
                },
                '&.Mui-disabled': {
                  bgcolor: 'action.disabledBackground',
                  color: 'action.disabled',
                },
              }}
            >
              <Send />
            </IconButton>
          </Grid>
        </Grid>
      </Box>

      {/* Confirmation Dialog */}
      <Dialog
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        disableEscapeKeyDown={preventEscapeKeyClosing}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>Send message?</DialogTitle>
        <DialogContent>
          {/* Message Preview */}
          <Box sx={{ mb: 2 }}>
            <Typography variant='subtitle2' color='text.secondary' gutterBottom>
              Message:
            </Typography>
            <Box
              sx={{
                p: 2,
                bgcolor: 'action.hover',
                borderRadius: 1,
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
              }}
            >
              {message}
            </Box>
          </Box>

          {/* Attachments Preview */}
          {attachments.length > 0 && (
            <Box>
              <Typography variant='subtitle2' color='text.secondary' gutterBottom>
                Attachments ({attachments.length}):
              </Typography>
              {renderAttachmentPreviews(previews)}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmDialogOpen(false)} color='inherit'>
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant='contained' color='primary'>
            Send Message
          </Button>
        </DialogActions>
      </Dialog>

      {/* Reopen Conversation Dialog */}
      <Dialog
        open={isReopenDialogOpen}
        onClose={() => setIsReopenDialogOpen(false)}
        disableEscapeKeyDown={preventEscapeKeyClosing}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>Reopen Conversation?</DialogTitle>
        <DialogContent>
          <Typography>
            This conversation is currently closed. Sending a message will reopen the conversation.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsReopenDialogOpen(false)} color='inherit'>
            Cancel
          </Button>
          <Button onClick={sendMessage} variant='contained' color='primary'>
            Send and Reopen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
