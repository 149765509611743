import ChatIcon from '@mui/icons-material/Chat';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, IconButton, Stack, Tooltip, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuth } from '../../utils/auth/AuthService';
import { useNotifications } from '../../utils/components/AppBar/Notifications/NotificationsContext';
import { NotificationsMenuDrawer } from '../../utils/components/AppBar/Notifications/NotificationsMenuDrawer';
import { useConversation } from '../../views/SysAdmin/SysAdminModules/UserFeedback/Conversations/ConversationsContext';
import { NewFeedbackDialog } from '../../views/SysAdmin/SysAdminModules/UserFeedback/Conversations/Dialogs/NewFeedbackDialog';

const { REACT_APP_API_URL } = process.env;

export const CommunicationsButtons = ({
  notificationMenuOpen = false,
  userMenuOpen = false,
  handleOpenNotificationMenu,
  handleCloseNotificationMenu,
}: {
  handleOpenNotificationMenu: any;
  handleCloseNotificationMenu: any;
  notificationMenuOpen: boolean;
  userMenuOpen: boolean;
}) => {
  const { user } = useAuth();
  const { setSelectedConversation, unreadConversationCount, fetchUnreadConversatonsCount } =
    useConversation();
  const [messagesOpen, setMessagesOpen] = useState(false);
  const [unreadConversations, setUnreadConversations] = useState(0);
  const { notifications } = useNotifications();
  const theme = useTheme();

  const fetchUnreadCount = async () => {
    fetchUnreadConversatonsCount({ userSeq: user.userSeq });
  };

  useEffect(() => {
    fetchUnreadCount();
    const interval = setInterval(
      () => fetchUnreadConversatonsCount({ userSeq: user.userSeq }),
      300000 // 5 mins
    );
    return () => clearInterval(interval);
  }, []);

  const closeMessages = () => {
    setSelectedConversation(null);

    setMessagesOpen(false);
    setSelectedConversation(null);
    fetchUnreadConversatonsCount({ userSeq: user.userSeq });
  };

  const unreadNotifications = notifications.filter(n => !n.notificationRead && n.isActive);

  return (
    <Stack direction='row' spacing={1} sx={{ p: 0.5, display: 'flex' }}>
      <IconButton
        title='Messages'
        sx={{ display: { xs: 'none', sm: 'flex' } }}
        onClick={() => setMessagesOpen(true)}
      >
        <Tooltip title='Messages'>
          <Badge badgeContent={unreadConversationCount} color='error'>
            <ChatIcon sx={{ color: theme.palette.text.primary }} />
          </Badge>
        </Tooltip>
      </IconButton>
      <IconButton title='Notifications' onClick={handleOpenNotificationMenu}>
        <Tooltip title='Notifications'>
          <Badge badgeContent={unreadNotifications.length} color='error'>
            <NotificationsIcon sx={{ color: theme.palette.text.primary }} />
          </Badge>
        </Tooltip>
      </IconButton>

      <NotificationsMenuDrawer
        handleCloseNotificationMenu={handleCloseNotificationMenu}
        notificationMenuOpen={notificationMenuOpen}
      />

      <NewFeedbackDialog
        open={messagesOpen}
        onClose={closeMessages}
        onConversationCreated={() => {
          fetchUnreadCount();
        }}
      />
    </Stack>
  );
};
