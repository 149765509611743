import { Box, ThemeProvider } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useMemo, useState } from 'react';
import './App.css';
import { UnsavedChangesProvider } from './Context/UnsavedChangesContext';
import AppRoutes from './Routes';
import MLAppBar from './components/AppBar/MLAppBar';
import { TimeoutLogic } from './components/TimeOut/TimeoutLogic';
import { useAuth } from './utils/auth/AuthService';
import { NotificationsProvider } from './utils/components/AppBar/Notifications/NotificationsContext';
import dimensions from './utils/dimensions';
import { CaseSearchProvider } from './utils/hooks/CaseSearch/useCaseSearch.hook';
import getAppTheme from './utils/theme/getAppTheme';
import { ConversationProvider } from './views/SysAdmin/SysAdminModules/UserFeedback/Conversations/ConversationsContext';

LicenseInfo.setLicenseKey(
  '48488c938623eae9e9ed50ece5269c38Tz04NzcxMSxFPTE3NDM3MDc2ODEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
);

function App() {
  const auth = useAuth();
  const [mode, setMode] = useState('light');
  // const [appEnvironmentIsUAT, setAppEnvironmentIsUAT] = useState(true);
  const theme = getAppTheme(mode);
  const { REACT_APP_API_URL } = process.env;

  // useEffect(() => {
  //   fetch(REACT_APP_API_URL + 'isappenvironmentuat', {
  //     method: 'GET',
  //   })
  //     .then(res => {
  //       return res.json();
  //     })
  //     .then(data => {
  //       console.log(data);
  //       setAppEnvironmentIsUAT(data);
  //     })
  //     .catch(e => {
  //       //alert(e);
  //     });
  // }, []);

  useEffect(() => {
    if (auth.isAuthenticated && auth.ready) {
      setMode(auth.user.isDarkMode ? 'dark' : 'light');
    }
  }, [auth?.user?.isDarkMode, auth.isAuthenticated, auth.ready]);

  const authenticated = useMemo(() => {
    return auth.isAuthenticated && auth.ready;
  }, [auth.isAuthenticated, auth.ready]);

  // Only render app bars if user is logged in
  return authenticated ? (
    <Box sx={{ overflow: 'hidden', height: '100%', width: '100%' }}>
      <UnsavedChangesProvider>
        <ThemeProvider theme={theme}>
          <NotificationsProvider>
            <ConversationProvider>
              <CaseSearchProvider>
                <MLAppBar />

                <AppRoutes
                  sx={{
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.text.primary,
                    overflow: 'auto',
                    width: '100%',
                    height: `calc(100% - ${dimensions.app_bar_height})`,
                    // height: '100%',
                  }}
                />
              </CaseSearchProvider>
            </ConversationProvider>
          </NotificationsProvider>
        </ThemeProvider>

        {(() => {
          if (auth.ready && auth.user) {
            return <TimeoutLogic />;
          }
        })()}
      </UnsavedChangesProvider>
      {/* {appEnvironmentIsUAT && (
        <Box
          sx={{
            width: '100%',
            height: `calc(100% - ${dimensions.app_bar_height}`,
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 10000000,
            opacity: 0.05,
            color: 'white',
            overflow: 'hidden',
            pointerEvents: 'none',
          }}
        >
          <h1
            style={{
              position: 'absolute',
              fontSize: 175,
              pointerEvents: 'none',
            }}
          >
            <Stack spacing={2}>
              {[...Array(30)].map((_, outerIndex) => (
                <Stack direction='row' spacing={2}>
                  {[...Array(30)].map((_, innerIndex) => (
                    <b
                      style={{
                        transform: `rotate(-35deg) translate(-${outerIndex * 275 + 100}px, -100px)`,
                      }}
                    >
                      UAT
                    </b>
                  ))}
                </Stack>
              ))}
            </Stack>
          </h1>
        </Box>
      )} */}
    </Box>
  ) : (
    <ThemeProvider theme={theme}>
      <AppRoutes sx={{ overflow: 'auto', height: '100%' }} />
    </ThemeProvider>
  );
}

export default App;
