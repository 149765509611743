import { CancelRounded } from '@mui/icons-material';
import {
  Autocomplete,
  AutocompleteRenderOptionState,
  Checkbox,
  Chip,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';
import CaseViewDateTimeField, {
  ActionList,
} from '../../../../components/CaseView/CaseViewDateTimeField';
import { LiveryCard } from '../../../../components/LiveryCard';
import { PathologyCard } from '../../../../components/PathologyCard';
import { PersonCard } from '../../../../components/PersonCard';
import { useUnsavedChanges } from '../../../../Context/UnsavedChangesContext';
import { useOptionsAPI } from '../../../../utils/api/useOptions.hook';
import { useAuth } from '../../../../utils/auth/AuthService';
import { CaseViewOptionsAutocomplete } from '../../../../utils/components/CaseViewOptionsAutocomplete';
import { FormikTextField } from '../../../../utils/components/FormikTextField';
import { Livery } from '../../../../utils/constants/Livery';
import { MEAction } from '../../../../utils/constants/MEAction';
import { Option } from '../MortuaryModules/XrayRequestForm/xrayrequestform.constants';
import { MAiDFields } from './MAiDFields';

const { REACT_APP_API_URL } = process.env;

export function MEActionView() {
  const { user } = useAuth();
  const formik = useFormikContext<any>();
  const { handleResetdisabledFields } = useResetDisabledFields();
  const [meOptions, setMEOptions] = useState([]);
  const { setUnsavedChanges } = useUnsavedChanges();

  const { selectedValues, showFlags } = useMEActionView();

  const authorizedToEdit = useMemo(
    () =>
      user?.roleCheck([
        'caef1f91-c392-4b53-a466-a8f418d8d25a', // Medical Examiner
        'd1582600-5f86-49dd-bab7-6f7205bfeffd', // Sys admin
        'e0556a35-b626-485d-9090-0d1a23abf38b', // Investigator
        '18c92818-6969-466b-a82b-c4817fdfacf4', // Data entry (HISTORIC-DATAENTRYONLY)
        '7bea8708-b1c8-4bf5-8fda-bc023453f072', // Admin assistant
      ]) || false,
    [user]
  );

  const fetchMEs = async () => {
    const queryParams = new URLSearchParams();
    if (formik.values?.caseSummary?.jdxSeq) {
      queryParams.append('jdxList', formik.values?.caseSummary?.jdxSeq);
    }

    await fetch(`${REACT_APP_API_URL}get-pathologist-options?${queryParams.toString()}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setMEOptions(data);
      })
      .catch(e => {
        alert(e);
      });
  };

  const { options: MLDI_and_ME_options } = useOptionsAPI(
    `getinvestigatorandmeoptions?jdxSeq=${formik.values?.caseSummary?.jdxSeq}`
  );

  useEffect(() => {
    fetchMEs();
  }, []);

  const externalExamInvestigators: ExternalExamInvestigatorRecord[] =
    formik.values?.caseInvestigations?.discovery?.externalExamInvestigators || [];

  const externalExamInvestigatorOptions: Option[] = useMemo(() => {
    return externalExamInvestigators.filter(record => !record.isDeleted).map(record => record.user);
  }, [externalExamInvestigators]);

  const handleExternalExamInvestigatorChange = (event: any, newValue: Option[]) => {
    const existingRecords: ExternalExamInvestigatorRecord[] =
      formik.values.caseInvestigations?.discovery?.externalExamInvestigators || [];

    const existingOptionSeqs = existingRecords
      .filter(record => !record.isDeleted)
      .map(record => record.user.optionSeq);

    const newOptionSeqs = newValue.map(option => option.optionSeq);
    const addedOptions = newValue.filter(option => !existingOptionSeqs.includes(option.optionSeq));

    const removedOptionSeqs = existingOptionSeqs.filter(
      optionSeq => !newOptionSeqs.includes(optionSeq)
    );

    // Mark removed records as deleted
    const updatedExistingRecords = existingRecords.map(record => {
      if (removedOptionSeqs.includes(record.user.optionSeq)) {
        return { ...record, isDeleted: true, isActive: false };
      }
      return record;
    });

    // Add new records with isNew flag
    const newRecords = addedOptions.map(option => ({
      id: uuidv4(),
      user: option,
      isActive: true,
      isNew: true,
    }));

    // Update the formik field with the combined records
    formik.setFieldValue('caseInvestigations.discovery.externalExamInvestigators', [
      ...updatedExistingRecords,
      ...newRecords,
    ]);
    setUnsavedChanges();
  };

  const renderOptionWithCheckbox = useCallback(
    (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: Option,
      state: AutocompleteRenderOptionState
    ) => {
      return (
        <li {...props}>
          <Checkbox
            icon={<MdCheckBoxOutlineBlank />}
            checkedIcon={<MdOutlineCheckBox />}
            style={{ marginRight: 8 }}
            checked={state.selected}
            onChange={event => event.stopPropagation()}
          />
          {option.optionName}
        </li>
      );
    },
    []
  );
  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
          <Typography variant='subtitle1' color='gray'>
            <strong>ME ACTION</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <CaseViewOptionsAutocomplete
            label='Assigned MLDI'
            optionsEndpoint={`get-investigator-options?jdxList=${encodeURIComponent(
              formik.values?.caseSummary?.jdxSeq
            )}`}
            formikField='caseInvestigations.assignedInvestigator'
            authorizedToEdit={r => [
              r.Investigator,
              r.Medical_Examiner,
              r.Administrative_Assistant,
              r.Historic_Dataentryonly,
              r.Systems_Admin,
            ]}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              disabled={!formik.status.editing || !authorizedToEdit}
              name='caseInvestigations.meNotified'
              label='ME Notified On'
              value={
                formik.values?.caseInvestigations?.meNotified
                  ? dayjs(formik.values?.caseInvestigations?.meNotified) || null
                  : null
              }
              timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
              onChange={date => {
                const localDate = date ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss') : null;
                formik.setFieldValue('caseInvestigations.meNotified', localDate);
                setUnsavedChanges();
              }}
              // disableFuture={true}
              ampm={false}
              slots={{
                actionBar: ActionList,
              }}
              slotProps={{
                field: { clearable: true },
                textField: { id: 'caseInvestigations.meNotified' },
              }}
              sx={{ minWidth: '100%' }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid item container spacing={2}>
            <Grid item xs={12} md={2}>
              <CaseViewOptionsAutocomplete
                label='ME Action'
                optionsEndpoint='getmeactionoptions'
                formikField='caseInvestigations.meAction'
                authorizedToEdit={r => [
                  r.Investigator,
                  r.Medical_Examiner,
                  r.Administrative_Assistant,
                  r.Historic_Dataentryonly,
                  r.Systems_Admin,
                ]}
                onOptionChange={newOption => {
                  if (newOption === null) {
                    formik.setFieldValue('caseInvestigations.meActionType', null);
                  }
                  formik.setFieldValue('caseInvestigations.investigationReason', null);

                  // if (newOption.optionSeq.toUpperCase() != ME_Action.Accepted) {
                  //   formik.setStatus({
                  //     ...formik?.status,
                  //     moduleVisibilityStatus: {
                  //       ...formik.status?.moduleVisibilityStatus,
                  //       mortuary: {
                  //         ...formik.status?.moduleVisibilityStatus?.mortuary,
                  //         enabled: false,
                  //       },
                  //       medicalImaging: {
                  //         ...formik.status?.moduleVisibilityStatus?.medicalImaging,
                  //         enabled: false,
                  //       },
                  //     },
                  //   });
                  // }

                  handleResetdisabledFields('meAction', newOption);
                }}
                textFieldProps={{
                  variant: 'filled',
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                defaultValue={{ optionName: 'None', optionSeq: '' }}
              />
            </Grid>

            {/* {showFlags.showMEActionType && (
              <Grid item xs={12} md={2}>
                <Type />
              </Grid>
            )} */}
            {showFlags.showTransfer && (
              <Grid item xs={12} md={2}>
                <CaseViewOptionsAutocomplete
                  label='Transfer Case To'
                  optionsEndpoint='getjurisdictionoptions'
                  formikField='caseInvestigations.transferToJdxSeq'
                  authorizedToEdit={r => [
                    r.Investigator,
                    r.Medical_Examiner,
                    r.Administrative_Assistant,
                    r.Historic_Dataentryonly,
                    r.Systems_Admin,
                  ]}
                  onlyUseOptionSeq={true}
                  textFieldProps={{
                    variant: 'filled',
                    InputLabelProps: {
                      shrink: true,
                    },
                  }}
                />
              </Grid>
            )}
            {showFlags.showReason && (
              <Grid item xs={12} md={2}>
                <Reason />
              </Grid>
            )}
            {selectedValues.meAction === MEAction.Constants.Disposition.MAID && (
              <Grid item xs={12} md={2}>
                <CaseViewOptionsAutocomplete
                  label='Office notified via'
                  optionsEndpoint='getmaidnotificationoptions'
                  formikField='caseMAIDDetails.notificationType'
                  authorizedToEdit={r => [
                    r.Investigator,
                    r.Medical_Examiner,
                    r.Administrative_Assistant,
                    r.Historic_Dataentryonly,
                    r.Systems_Admin,
                  ]}
                  textFieldProps={{
                    variant: 'filled',
                    InputLabelProps: {
                      shrink: true,
                    },
                  }}
                />
              </Grid>
            )}
            {showFlags.showReason &&
              selectedValues.transferReason === MEAction.Constants.TransferReason.Other && (
                <Grid item xs={12} md={3}>
                  <TextField
                    disabled={!formik.status.editing || !authorizedToEdit}
                    id='caseInvestigations.otherTransferReason'
                    name='caseInvestigations.otherTransferReason'
                    label='Other Transfer Reason'
                    placeholder='Other Transfer Reason'
                    variant='filled'
                    fullWidth
                    value={formik.values?.caseInvestigations?.otherTransferReason || ''}
                    onChange={e => {
                      formik.setFieldValue(
                        'caseInvestigations.otherTransferReason',
                        e.target.value
                      );
                      setUnsavedChanges();
                    }}
                    error={
                      formik.touched.otherTransferReason &&
                      Boolean(formik.errors.otherTransferReason)
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              )}
            {showFlags.showPathologist && (
              <Grid item xs={12} md={2}>
                <CaseViewOptionsAutocomplete
                  label='Pathologist'
                  optionsEndpoint={`get-pathologist-options?jdxList=${encodeURIComponent(
                    formik.values?.caseSummary?.jdxSeq
                  )}`}
                  formikField='caseMortuary.assignedUser'
                  authorizedToEdit={r => [
                    r.Investigator,
                    r.Medical_Examiner,
                    r.Administrative_Assistant,
                    r.Historic_Dataentryonly,
                    r.Systems_Admin,
                  ]}
                  textFieldProps={{
                    variant: 'filled',
                    InputLabelProps: {
                      shrink: true,
                    },
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        {showFlags.showTransfer && (
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormikTextField
                  label='Transfer Notes'
                  formikField='caseInvestigations.transferNotes'
                  authorizedToEdit={r => [
                    r.Investigator,
                    r.Medical_Examiner,
                    r.Administrative_Assistant,
                    r.Historic_Dataentryonly,
                    r.Systems_Admin,
                  ]}
                  multiline
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {selectedValues.meAction === MEAction.Constants.Disposition.MAID && (
          <Grid item xs={12} md={12}>
            <MAiDFields />
          </Grid>
        )}
        {showFlags.showRelease && (
          <Grid xs={12} md={12} item>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                <CaseViewOptionsAutocomplete
                  label='Assigned POC'
                  optionsEndpoint={`get-pathologist-options?jdxList=${encodeURIComponent(
                    formik.values?.caseSummary?.jdxSeq
                  )}`}
                  formikField='caseInvestigations.approvingPathologist'
                  authorizedToEdit={r => [
                    r.Investigator,
                    r.Medical_Examiner,
                    r.Administrative_Assistant,
                    r.Historic_Dataentryonly,
                    r.Systems_Admin,
                  ]}
                  onOptionChange={newOption => {
                    if (newOption != null) {
                      formik.setFieldValue(
                        'caseInvestigations.pocAssignedDateTime',
                        new Date().toISOString()
                      );
                    }
                  }}
                />
              </Grid>
              <CaseViewDateTimeField
                xs={12}
                md={2}
                authorizedToEdit={authorizedToEdit}
                fieldName='caseInvestigations.pocAssignedDateTime'
                label='POC Assigned On'
                sm={undefined}
                lg={undefined}
                xl={undefined}
                sx={undefined}
              />
            </Grid>
          </Grid>
        )}
        {showFlags.showRelease && (
          <Grid item xs={12} md={5}>
            <Paper
              variant='outlined'
              sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'flex-start',
                px: 2,
                pb: 2,
                pt: 1,
              }}
            >
              <PathologyCard
                formikFields={{
                  decedentCauseOfDeath: 'casePathology.causeOfDeath',
                  decedentMannerOfDeath: 'casePathology.mannerOfDeath',
                  decedentDrugToxicityConfirmed: 'casePathology.drugToxicityConfirmed',
                  decedentInfectiousDiseaseConfirmed: 'casePathology.infectiousDiseaseConfirmed',
                  decedentOpiodConfirmed: 'casePathology.opioidConfirmed',
                }}
              />
            </Paper>
          </Grid>
        )}
        {showFlags.showRelease && (
          <Grid item xs={12} sm={12} md={5}>
            <Paper
              variant='outlined'
              sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'flex-start',
                px: 2,
                pb: 2,
                pt: 1,
              }}
            >
              <PersonCard
                formikFields={{
                  firstName: 'caseInvestigations.physician.firstName',
                  middleName: 'caseInvestigations.physician.middleName',
                  lastName: 'caseInvestigations.physician.lastName',
                  suffix: 'caseInvestigations.physician.suffix',
                  contactItem: 'caseInvestigations.physician.contactItem',
                }}
                label='Release Physician'
              />
            </Paper>
          </Grid>
        )}
        {/* getinvestigatorandmeoptions?jdxSeq=' + formik.values?.caseSummary?.jdxSeq */}
        {showFlags.showExternalExam && (
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                <Autocomplete
                  multiple
                  disabled={!formik.status.editing || !authorizedToEdit}
                  options={MLDI_and_ME_options}
                  getOptionLabel={option => option.optionName}
                  getOptionKey={o => o.optionSeq}
                  value={externalExamInvestigatorOptions}
                  onChange={handleExternalExamInvestigatorChange}
                  renderInput={params => <TextField {...params} label='External Exam MLDI' />}
                  isOptionEqualToValue={(option, value) =>
                    option?.optionSeq?.toUpperCase() === value?.optionSeq?.toUpperCase()
                  }
                  limitTags={1}
                  renderOption={renderOptionWithCheckbox}
                  disableCloseOnSelect
                  renderTags={(v, getTagProps) => {
                    return v.map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        sx={{
                          height: 'auto',
                          '& .MuiChip-label': {
                            display: 'flex',
                            alignItems: 'center',
                          },
                          '& .MuiChip-deleteIcon': {
                            fontSize: '16px',
                          },
                        }}
                        label={option.optionName}
                        deleteIcon={<CancelRounded />}
                      />
                    ));
                  }}
                />
                {/* <CaseViewOptionsAutocomplete<>
                  label='External Exam MLDI'
                  optionsEndpoint={` getinvestigatorandmeoptions?jdxSeq=${encodeURIComponent(
                    formik.values?.caseSummary?.jdxSeq
                  )}`}
                  formikField='caseInvestigations.discovery.sceneExaminedBy'
                  authorizedToEdit={r => [
                    r.Investigator,
                    r.Medical_Examiner,
                    r.Administrative_Assistant,
                    r.Historic_Dataentryonly,
                    r.Systems_Admin,
                  ]}
                  getOptionKey={()}
                /> */}
              </Grid>

              <Grid item xs={12} md={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    disabled={!formik.status.editing || !authorizedToEdit}
                    name='caseMortuary.examStartDateTime'
                    label='Exam Start DateTime'
                    value={
                      formik.values?.caseMortuary?.examStartDateTime
                        ? dayjs(formik.values?.caseMortuary?.examStartDateTime) || null
                        : null
                    }
                    onChange={date => {
                      const localDate = date ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss') : null;
                      formik.setFieldValue('caseMortuary.examStartDateTime', localDate);
                      setUnsavedChanges();
                    }}
                    timeSteps={{ minutes: 1, hours: 1 }}
                    disableFuture={false}
                    ampm={false}
                    slotProps={{
                      field: { clearable: true },
                      textField: { id: 'caseMortuary.examStartDateTime' },
                    }}
                    sx={{ minWidth: '100%' }}
                  />
                </LocalizationProvider>
              </Grid>
              {/* <CaseViewDateTimeField
                xs={12}
                md={2}
                authorizedToEdit={authorizedToEdit}
                fieldName='caseMortuary.examStartDateTime'
                label='Exam Start DateTime'
                sm={undefined}
                lg={undefined}
                xl={undefined}
                sx={undefined}
              /> */}
            </Grid>
          </Grid>
        )}
        {showFlags.showLiveryOptions && (
          <Grid item xs={12} md={10}>
            <LiveryComponent />
          </Grid>
        )}
      </Grid>
    </form>
  );
}

function useResetDisabledFields() {
  const formik = useFormikContext<any>();

  const handleResetdisabledFields = (fieldName: string, value: any) => {
    if (fieldName === 'meAction') {
      if (value?.optionSeq !== MEAction.Constants.Disposition.Accepted) {
        formik.setFieldValue('caseInvestigations.investigationReason', null);
        formik.setFieldValue('caseInvestigations.meActionType', null);
      }

      if (value?.optionSeq !== MEAction.Constants.Disposition.Release) {
        formik.setFieldValue('caseInvestigations.releaseReason', null);
        formik.setFieldValue('caseInvestigations.physician', null);
        formik.setFieldValue('casePathology.meApproval', '');
        formik.setFieldValue('casePathology.rejectedReason', '');
        formik.setFieldValue('caseInvestigations.approvingPathologist', null);
      }

      if (value?.optionSeq !== MEAction.Constants.Disposition.Transfer) {
        formik.setFieldValue('caseInvestigations.transferReasonSeq', null);
        formik.setFieldValue('caseInvestigations.transferToJdxSeq', null);
        formik.setFieldValue('caseInvestigations.transferNotes', null);
      }

      if (
        value?.optionSeq !== MEAction.Constants.Disposition.Accepted &&
        value?.optionSeq !== MEAction.Constants.Disposition.StorageOnly
      ) {
        formik.setFieldValue('caseInvestigations.liveryOption', null);
      }
    }

    if (['liveryOption', 'meAction'].includes(fieldName)) {
      if (
        value?.optionSeq !== Livery.Constants.LiveryOptions.Livery_To_Transport_Body &&
        value?.optionSeq !== MEAction.Constants.Disposition.Accepted &&
        value?.optionSeq !== MEAction.Constants.Disposition.StorageOnly
      ) {
        formik.setFieldValue('caseInvestigations.liveryUserSeq', null);
      }
    }

    if (fieldName === 'liveryOption') {
      if (value?.optionSeq !== Livery.Constants.LiveryOptions.Livery_Not_Required) {
        formik.setFieldValue('caseInvestigations.noLiveryBodyTransportedBy', null);
        formik.setFieldValue('caseInvestigations.noLiveryBodyTransportedByOther', '');
        formik.setFieldValue('caseInvestigations.remainTagID', null);
      }

      formik.setFieldValue('caseInvestigations.itemsRequested', []);
      formik.setFieldValue('caseInvestigations.otherItemRequested', null);
    }

    if (fieldName === 'meActionType') {
      if (value?.optionSeq !== MEAction.Constants.DispositionType.Admitted_To_Morgue) {
        formik.setFieldValue('caseInvestigations.liveryUserSeq', null);
        formik.setFieldValue('caseInvestigations.liveryOption', null);
        formik.setFieldValue('caseInvestigations.noLiveryBodyTransportedBy', null);
        formik.setFieldValue('caseInvestigations.noLiveryBodyTransportedByOther', '');
        formik.setFieldValue('caseInvestigations.certifiedBy', null);
      }
    }
  };

  return { handleResetdisabledFields };
}

const springConfig = {
  type: 'tween',
  duration: 0.3,
  ease: 'easeInOut',
};

const exitConfig = {
  type: 'spring',
  stiffness: 300,
  damping: 30,
  scale: 0.8,
  opacity: 0,
};

const animationConfig = {
  initial: { scale: 0 },
  animate: { scale: 1 },
  exit: { scale: 0 },
  transition: {
    type: 'spring',
    stiffness: 300,
    damping: 30,
    duration: 0.15,
  },
};

const LiveryComponent = () => (
  <Paper
    variant='outlined'
    sx={{
      height: '100%',
      display: 'flex',
      alignItems: 'flex-start',
      px: 2,
      pb: 2,
      pt: 1,
    }}
  >
    <LiveryCard label='Livery' />
  </Paper>
);

const Reason = React.memo(() => {
  const formik = useFormikContext<any>();
  const { selectedValues, showFlags } = useMEActionView();

  if (selectedValues.meAction === MEAction.Constants.Disposition.Accepted) {
    return (
      <CaseViewOptionsAutocomplete
        label='Reason for Investigation'
        optionsEndpoint='getinvestigationreasonoptions'
        formikField='caseInvestigations.investigationReason'
        authorizedToEdit={r => [
          r.Investigator,
          r.Medical_Examiner,
          r.Administrative_Assistant,
          r.Historic_Dataentryonly,
          r.Systems_Admin,
        ]}
        textFieldProps={{
          variant: 'filled',
        }}
      />
    );
  }

  if (
    selectedValues.meActionType === MEAction.Constants.DispositionType.Facilitated_External_Exam
  ) {
    return (
      <CaseViewOptionsAutocomplete
        label='Reason for Facilitated External Exam'
        optionsEndpoint='getreleasereasonoptions?ReasonCode=F'
        formikField='caseInvestigations.facilitatedExternalExamReasonSeq'
        onlyUseOptionSeq={true}
        authorizedToEdit={r => [
          r.Investigator,
          r.Medical_Examiner,
          r.Administrative_Assistant,
          r.Historic_Dataentryonly,
          r.Systems_Admin,
        ]}
        onOptionChange={() => {
          formik.setFieldValue('caseInvestigations.investigationReason', null);
        }}
        textFieldProps={{
          variant: 'filled',
        }}
      />
    );
  }

  if (
    selectedValues.meAction === MEAction.Constants.Disposition.Record_Review &&
    selectedValues.meActionType === MEAction.Constants.DispositionType.Facilitated_External_Exam
  ) {
    return (
      <CaseViewOptionsAutocomplete
        label='Reason for Facilitated External Exam'
        optionsEndpoint='getreleasereasonoptions?ReasonCode=F'
        formikField='caseInvestigations.facilitatedExternalExamReasonSeq'
        onlyUseOptionSeq={true}
        authorizedToEdit={r => [
          r.Investigator,
          r.Medical_Examiner,
          r.Administrative_Assistant,
          r.Historic_Dataentryonly,
          r.Systems_Admin,
        ]}
        onOptionChange={() => {
          formik.setFieldValue('caseInvestigations.investigationReason', null);
        }}
        textFieldProps={{
          variant: 'filled',
        }}
      />
    );
  }

  if (selectedValues.meAction === MEAction.Constants.Disposition.Facilitated_Release) {
    return (
      <CaseViewOptionsAutocomplete
        label='Reason for Facilitated External Exam'
        optionsEndpoint='getreleasereasonoptions?ReasonCode=F'
        formikField='caseInvestigations.facilitatedExternalExamReasonSeq'
        onlyUseOptionSeq={true}
        authorizedToEdit={r => [
          r.Investigator,
          r.Medical_Examiner,
          r.Administrative_Assistant,
          r.Historic_Dataentryonly,
          r.Systems_Admin,
        ]}
        onOptionChange={() => {
          formik.setFieldValue('caseInvestigations.investigationReason', null);
        }}
        textFieldProps={{
          variant: 'filled',
        }}
      />
    );
  }

  if (selectedValues.meAction === MEAction.Constants.Disposition.MAID) {
    return (
      <CaseViewOptionsAutocomplete
        label='MAiD Disposition'
        optionsEndpoint='getmaiddispsitionoptions'
        formikField='caseMAIDDetails.mAiDDisposition'
        authorizedToEdit={r => [
          r.Investigator,
          r.Medical_Examiner,
          r.Administrative_Assistant,
          r.Historic_Dataentryonly,
          r.Systems_Admin,
        ]}
        textFieldProps={{
          variant: 'filled',
        }}
      />
    );
  }

  if (selectedValues.meAction === MEAction.Constants.Disposition.Release) {
    return (
      <CaseViewOptionsAutocomplete
        label='Reason for Release'
        optionsEndpoint='getreleasereasonoptions?ReasonCode=C'
        formikField='caseInvestigations.releaseReason'
        authorizedToEdit={r => [
          r.Investigator,
          r.Medical_Examiner,
          r.Administrative_Assistant,
          r.Historic_Dataentryonly,
          r.Systems_Admin,
        ]}
        textFieldProps={{
          variant: 'filled',
        }}
      />
    );
  }

  if (selectedValues.meAction === MEAction.Constants.Disposition.Transfer) {
    return (
      <CaseViewOptionsAutocomplete
        label='Reason for Transfer'
        optionsEndpoint='gettransferreasonoptions'
        formikField='caseInvestigations.transferReasonSeq'
        onOptionChange={(o, r, d) => {
          // formik.setFieldValue('caseInvestigations.transferReasonSeq', o?.optionSeq);
          if (o?.optionSeq?.toUpperCase() !== MEAction.Constants.TransferReason.Other) {
            formik.setFieldValue('caseInvestigations.otherTransferReason', '');
          }
        }}
        onlyUseOptionSeq={true}
        authorizedToEdit={r => [
          r.Investigator,
          r.Medical_Examiner,
          r.Administrative_Assistant,
          r.Historic_Dataentryonly,
          r.Systems_Admin,
        ]}
        textFieldProps={{
          variant: 'filled',
        }}
      />
    );
  }

  return null;
});

const Type = React.memo(() => {
  const formik = useFormikContext<any>();
  const { handleResetdisabledFields } = useResetDisabledFields();

  const { selectedValues, showFlags } = useMEActionView();
  return (
    <CaseViewOptionsAutocomplete
      label='Type'
      optionsEndpoint='getdispositiontypeoptions'
      customOptionsHandler={options => {
        if (selectedValues.meAction === MEAction.Constants.Disposition.Record_Review) {
          return options.filter(
            o => o.optionSeq === MEAction.Constants.DispositionType.Record_Review_Only
          );
        }
        return options;
      }}
      formikField='caseInvestigations.meActionType'
      authorizedToEdit={r => [
        r.Investigator,
        r.Medical_Examiner,
        r.Administrative_Assistant,
        r.Historic_Dataentryonly,
        r.Systems_Admin,
      ]}
      onOptionChange={newOption => {
        formik.setFieldValue('caseInvestigations.investigationReason', null);
        formik.setFieldValue('caseInvestigations.certifiedBy', null);
        handleResetdisabledFields('meActionType', newOption);
      }}
      textFieldProps={{
        variant: 'filled',
      }}
    />
  );
});

export function useMEActionView() {
  const formik = useFormikContext<any>();

  // Memoize each selected value separately
  const meAction = useMemo(() => {
    return formik.values.caseInvestigations?.meAction?.optionSeq?.toUpperCase() || '';
  }, [formik.values.caseInvestigations?.meAction]);

  const meActionType = useMemo(() => {
    return formik.values.caseInvestigations?.meActionType?.optionSeq?.toUpperCase() || '';
  }, [formik.values.caseInvestigations?.meActionType]);

  const liveryOption = useMemo(() => {
    return formik.values.caseInvestigations?.liveryOption?.optionSeq?.toUpperCase() || '';
  }, [formik.values.caseInvestigations?.liveryOption]);

  const transferReason = useMemo(() => {
    return formik.values.caseInvestigations?.transferReasonSeq?.toUpperCase() || '';
  }, [formik.values.caseInvestigations?.transferReasonSeq]);

  const itemsRequested = useMemo(() => {
    return Array.isArray(formik.values.caseInvestigations?.itemsRequested)
      ? formik.values.caseInvestigations.itemsRequested
      : [];
  }, [formik.values.caseInvestigations?.itemsRequested]);

  // Combine selected values
  const selectedValues = useMemo(() => {
    return { meAction, meActionType, liveryOption, transferReason, itemsRequested };
  }, [meAction, meActionType, liveryOption, transferReason, itemsRequested]);

  // Memoize flags based on selected values
  const showPathologist = useMemo(() => {
    return [
      MEAction.Constants.Disposition.Record_Review,
      MEAction.Constants.Disposition.Facilitated_Release,
    ].includes(meAction);
  }, [meAction, meActionType]);

  const showMEActionType = useMemo(() => {
    return (
      meAction !== MEAction.Constants.Disposition.StorageOnly &&
      (meAction === MEAction.Constants.Disposition.Accepted ||
        meAction === MEAction.Constants.Disposition.Record_Review)
    );
  }, [meAction]);

  const showLiveryOptions = useMemo(() => {
    return (
      (meAction === MEAction.Constants.Disposition.Accepted ||
        meAction !== MEAction.Constants.Disposition.MAID) &&
      ![
        MEAction.Constants.Disposition.StorageOnly,
        MEAction.Constants.Disposition.Transfer,
        MEAction.Constants.Disposition.Record_Review,
        MEAction.Constants.Disposition.Release,
        MEAction.Constants.Disposition.Facilitated_Release,
      ].includes(meAction) &&
      ![
        MEAction.Constants.DispositionType.Record_Review_Only,
        MEAction.Constants.DispositionType.Facilitated_External_Exam,
      ].includes(meActionType)
    );
  }, [meAction, meActionType]);

  const showLiveryServiceUsers = useMemo(() => {
    return liveryOption === Livery.Constants.LiveryOptions.Livery_To_Transport_Body;
  }, [liveryOption]);

  const showLiveryRecoverySealNumber = useMemo(() => {
    return (
      liveryOption &&
      [
        Livery.Constants.LiveryOptions.Investigator_To_Transport_Body,
        Livery.Constants.LiveryOptions.Law_Enforcement_To_Transport_Body,
      ].includes(liveryOption)
    );
  }, [liveryOption]);

  const showLiveryItemsRequestedOtherItems = useMemo(() => {
    return (
      liveryOption === Livery.Constants.LiveryOptions.Livery_To_Transport_Body &&
      itemsRequested.some(
        (o: Option) => o.optionSeq?.toUpperCase() === Livery.Constants.LiveryItemsRequested.Other
      )
    );
  }, [liveryOption, itemsRequested]);

  const showFacilitatedRelease = useMemo(() => {
    return meAction === MEAction.Constants.Disposition.Facilitated_Release;
  }, [meAction]);

  const showRelease = useMemo(() => {
    return meAction === MEAction.Constants.Disposition.Release;
  }, [meAction]);

  const showTransfer = useMemo(() => {
    return meAction === MEAction.Constants.Disposition.Transfer;
  }, [meAction]);

  const showExternalExam = useMemo(() => {
    return meAction === MEAction.Constants.Disposition.Facilitated_Release;
  }, [meAction]);

  const showReason = useMemo(() => {
    return [
      MEAction.Constants.Disposition.Accepted,
      MEAction.Constants.Disposition.MAID,
      MEAction.Constants.Disposition.Release,
      MEAction.Constants.Disposition.Transfer,
      MEAction.Constants.Disposition.Facilitated_Release,
      MEAction.Constants.DispositionType.Facilitated_External_Exam,
    ].includes(meAction);
  }, [meAction, meActionType]);

  // Combine show flags
  const showFlags = useMemo(() => {
    return {
      showMEActionType,
      showLiveryOptions,
      showLiveryServiceUsers,
      showLiveryRecoverySealNumber,
      showLiveryItemsRequestedOtherItems,
      showFacilitatedRelease,
      showRelease,
      showTransfer,
      showExternalExam,
      showReason,
      showPathologist,
    };
  }, [
    showMEActionType,
    showLiveryOptions,
    showLiveryServiceUsers,
    showLiveryRecoverySealNumber,
    showLiveryItemsRequestedOtherItems,
    showFacilitatedRelease,
    showRelease,
    showTransfer,
    showExternalExam,
    showReason,
  ]);

  return { selectedValues, showFlags };
}

interface ExternalExamInvestigatorRecord {
  id: string;
  user: Option;
  isActive: boolean;
  isNew?: boolean;
  isDeleted?: boolean;
}
