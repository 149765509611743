import { TabContext, TabList } from '@mui/lab';
import { Box, Button, Grid, Tab } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../utils/auth/AuthService';
import ReportDataGrid from '../../../utils/components/ReportDataGrid/ReportDataGrid';
import { ME_Action } from '../../../utils/constants/constants';
import { PrintFileButton } from '../../../utils/functions/triggerPrintDialog';

export default function YourCasesView() {
  const [caseResults, setCaseResults] = useState([]);
  const [caseOverDueThreshold, setCaseOverDueThreshold] = useState([]);
  const [fileNotFound, setFileNotFound] = useState({ display: false, filename: '' });
  const [loading, setLoading] = useState(false);
  const [filteredCaseResults, setFilteredCaseResults] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [caseStatusFilter, setCaseStatusFilter] = useState('all');
  const { REACT_APP_API_URL } = process.env;
  const { user } = useAuth();

  // const handleDownload = async (fileseq, caseid) => {
  //   if (!fileseq || !caseid) {
  //     return;
  //   }

  //   const fileSeqs = [];
  //   fileSeqs.push(fileseq);

  //   let formData = new FormData();
  //   formData.append('caseId', caseid);
  //   formData.append('fileSeqsString', JSON.stringify(fileSeqs));

  //   var response = null;

  //   setDownloading(true);

  //   await fetch(REACT_APP_API_URL + 'downloadfiles', {
  //     method: 'POST',
  //     headers: {
  //       resposeType: 'blob',
  //       Authorization: 'Bearer ' + user.token,
  //     },
  //     body: formData,
  //   })
  //     .then((res) => {
  //       response = res;

  //       if (res.status == 401) {
  //         throw new Error('You are unauthorized to use this tool');
  //       } else if (res.status >= 400) {
  //         return res.text();
  //       }

  //       return res.blob();
  //     })
  //     .then((data) => {
  //       if (response.status >= 400) {
  //         throw new Error(data);
  //       } else {
  //           // Single file selected, download the file directly
  //           const disposition = response.headers.get('content-disposition');
  //           const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  //           const matches = filenameRegex.exec(disposition);
  //           const filename =
  //             matches !== null && matches[1]
  //               ? matches[1].replace(/['"]/g, '')
  //               : 'file';

  //           saveAs(data, filename);
  //       }
  //     })
  //     .catch((e) => {
  //       alert(e);
  //     });

  //   setDownloading(false);
  // };

  const fetchAppSettingsData = async () => {
    if (user === null) return;
    setLoading(true);
    await fetch(REACT_APP_API_URL + 'GlobalAppSettings/getallglobalappsettings', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        const threshold = data.find(
          res => res.globalAppSettingsSeq.toLowerCase() === '936145d6-78e7-44e2-bd87-ec55ebe90ce4'
        );

        if (threshold) {
          setCaseOverDueThreshold(parseInt(threshold.value));
        }
        // console.log('appsettings data: ', data);
        // console.log('threshold data: ', caseOverDueThreshold);
      })
      .catch(e => {
        alert(e);
      });
  };

  const renderFileListCell = params => {
    if (Array.isArray(params?.value) && params?.value?.length > 0) {
      return (
        <div>
          {
            // downloading ? (<div>Downloading...</div>
            //  ) : (
            params.value?.map((file, index) => (
              <div key={index}>
                {/* <a
                  href={`#`}
                  onClick={() =>
                    handleDownloadBlobOnly(file.fileseq, params.row.caseid, file.filename)
                  }
                >
                  {file.filename}
                </a> */}
                <PrintFileButton
                  filename={file.filename}
                  fileSeq={file.fileseq}
                  caseid={params.row.caseid}
                />
              </div>
            ))
            // )
          }
        </div>
      );
    } else {
      return null;
    }
  };

  const columns = [
    {
      field: 'caseid',
      headerName: 'CaseID',
      width: 105,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
      renderCell: params => {
        return (
          <Button
            variant='text'
            color='primary'
            onClick={event => {
              handleCaseClick(event, params);
            }}
            style={{ fontFamily: '"DataGrid", monospace', fontSize: '0.875rem' }}
          >
            <b> {params.formattedValue} </b>
          </Button>
        );
      },
    },
    {
      field: 'decedent',
      headerName: 'Decedent',
      minWidth: 150,
      flex: 1,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
      headerClassName: 'header-theme',
    },
    {
      field: 'menotifieddate',
      headerName: 'Reported',
      width: 140,
      editable: false,
      type: 'dateTime',
      valueGetter: value => {
        return value != null ? new Date(value) : null;
      },
      valueFormatter: value => {
        return value ? format(new Date(value), 'MM/dd/yyyy, HH:mm') : null;
      },
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
    },
    {
      field: 'idstatus',
      headerName: 'ID Status',
      minWidth: 100,
      flex: 1,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
    },
    {
      field: 'mannerofdeath',
      headerName: 'MOD',
      minWidth: 100,
      flex: 1,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
    },
    {
      field: 'causeofdeath',
      headerName: 'COD',
      minWidth: 180,
      flex: 1,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
    },
    {
      field: 'examtype',
      headerName: 'Exam Type',
      width: 120,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
    },
    {
      field: 'toxReport',
      headerName: 'Tox Report',
      minWidth: 150,
      flex: 1,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
      renderCell: renderFileListCell,
    },
    {
      field: 'autopsyReport',
      headerName: 'Pathology Report',
      minWidth: 150,
      flex: 1,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
      renderCell: renderFileListCell,
    },
    {
      field: 'datasheet',
      headerName: 'INV DataSheet',
      minWidth: 150,
      flex: 1,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
      renderCell: renderFileListCell,
    },
    {
      field: 'investigator',
      headerName: 'MLDI',
      minWidth: 120,
      flex: 1,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
    },
    {
      field: 'pathologist',
      headerName: 'Pathologist',
      minWidth: 120,
      flex: 1,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
    },
    {
      field: 'morguetech',
      headerName: 'Forensic Tech',
      minWidth: 100,
      flex: 1,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
    },
    {
      field: 'approvingpathologist',
      headerName: 'ME/POC',
      minWidth: 100,
      flex: 1,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
      valueGetter: value => {
        return value ? value.optionName : '';
      },
    },
    {
      field: 'casestatus',
      headerName: 'File',
      minWidth: 75,
      flex: 1,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
      valueFormatter: value => {
        if (value === 'O') {
          return 'Open';
        } else if (value === 'C') {
          return 'Closed';
        }
        return value;
      },
    },
  ];

  useEffect(() => {
    fetchAppSettingsData();
    loadData();
  }, []);

  useEffect(() => {
    const today = new Date();
    const overdueThresholdDate = new Date(today);
    overdueThresholdDate.setDate(today.getDate() - caseOverDueThreshold);
    setFilteredCaseResults(
      caseResults.filter(caseResult => {
        const isOverdue =
          caseResult.menotifieddate &&
          caseResult.meaction?.optionName?.toLowerCase() === 'accepted' &&
          new Date(caseResult.menotifieddate) < overdueThresholdDate;

        const isPendingRelease =
          caseResult.casestatus === 'O' &&
          caseResult?.isMeApproved !== true &&
          caseResult?.meaction?.optionSeq?.toLowerCase() === ME_Action.Release?.toLowerCase() &&
          caseResult?.approvingpathologist?.optionSeq?.toLowerCase() ===
            user?.userSeq?.toLowerCase();

        const matchesStatusFilter =
          caseStatusFilter === 'all' ||
          (caseStatusFilter === 'open' && caseResult.casestatus === 'O') ||
          (caseStatusFilter === 'overdue' && caseResult.casestatus === 'O' && isOverdue) ||
          (caseStatusFilter === 'closed' && caseResult.casestatus === 'C') ||
          (caseStatusFilter === 'pendingRelease' && isPendingRelease);

        // Return true if it matches the selected status filter
        return matchesStatusFilter;
      })
    );
  }, [caseResults, caseStatusFilter, caseOverDueThreshold]);

  const loadData = async () => {
    let formData = new FormData();

    formData.append('USERSEQ', user.userSeq);

    setLoading(true);

    if (user && user.token) {
      await fetch(REACT_APP_API_URL + 'YourCases', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setCaseResults(data);
          console.log('case results: ', data);
          setLoading(false);
        })
        .catch(e => {
          console.log(e);
        });
    } else {
      console.log('user or token is null');
    }
  };

  const getTitle = () => {
    if (caseStatusFilter === 'all') {
      return 'Your Cases';
    } else if (caseStatusFilter === 'open') {
      return 'Your Open Cases';
    } else if (caseStatusFilter === 'overdue') {
      return 'Your Overdue Cases';
    } else if (caseStatusFilter === 'closed') {
      return 'Your Closed Cases';
    } else {
      return 'Your Pending Release Cases';
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TabContext value={caseStatusFilter}>
          <TabList
            onChange={(event, newValue) => setCaseStatusFilter(newValue)}
            aria-label='Case status'
          >
            <Tab label='All' value='all' />
            <Tab label='Overdue' value='overdue' />
            <Tab label='Open' value='open' />
            <Tab label='Closed' value='closed' />
            <Tab label='Pending Releases' value='release' />
          </TabList>
        </TabContext>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ overflowX: 'auto' }}>
          <ReportDataGrid
            loading={loading}
            listresults={filteredCaseResults}
            columnsInput={columns}
            rowsperpage={10}
            title={getTitle()}
            idcolumn='caseid'
            headerAlignment='left'
            columnAlignment='left'
            casecount
            sx={{
              '& .MuiDataGrid-root': {
                fontSize: '0.875rem',
              },
              '& .MuiDataGrid-columnHeaders': {
                fontSize: '0.875rem',
              },
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
