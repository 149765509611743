import { AttachFile, Send } from '@mui/icons-material';
import { Box, Button, Chip, Dialog, Paper, TextField, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useAuth } from '../../../../../../utils/auth/AuthService';
import { Conversation, ConversationTypes } from '../conversation.types';
import { useConversation } from '../ConversationsContext';

interface StartFeedbackConversationDialogProps {
  open: boolean;
  onClose: () => void;
  onConversationCreated: (conversation: Conversation) => void;
  isDialog?: boolean;
}

export function StartFeedbackConversationDialog({
  open,
  onClose,
  onConversationCreated,
  isDialog = false,
}: StartFeedbackConversationDialogProps) {
  const { createConversation, creatingConversation, handleSelectConversation } = useConversation();
  const { user } = useAuth();
  const [topic, setTopic] = useState(`Feedback from ${user.userName}`);
  const [message, setMessage] = useState('');
  const [attachments, setAttachments] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  if (!open) {
    return null;
  }

  const handleAttachFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files);
      setAttachments(prev => [...prev, ...newFiles]);
    }
  };

  const handleRemoveAttachment = (index: number) => {
    setAttachments(prev => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    if (!topic.trim()) return;

    try {
      setIsLoading(true);

      const newConvo = await createConversation({
        attachments,
        conversationType: ConversationTypes.Feedback,
        message,
        participantUserSeqs: [],
        topic,
      });

      handleSelectConversation(newConvo);

      setTopic('');
      setMessage('');
      setAttachments([]);
      if (isDialog) {
        onClose();
      }
    } catch (error) {
      console.error('Error creating conversation:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const content = (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ position: 'relative', mb: 2 }}>
        <Typography variant='h6' component='h2'>
          Send Feedback
        </Typography>
        <Typography variant='subtitle1' color='text.secondary' sx={{ mt: 1 }}>
          What's going on? We review and respond to all feedback.
        </Typography>
      </Box>

      <TextField
        label='Subject'
        value={topic}
        onChange={e => setTopic(e.target.value)}
        fullWidth
        required
        size='small'
        disabled={creatingConversation}
      />

      <TextField
        value={message}
        placeholder='Example: When I try and save a case, nothing happens. CaseID: 12-345-6781'
        onChange={e => setMessage(e.target.value)}
        multiline
        rows={6}
        fullWidth
        size='small'
        disabled={creatingConversation}
      />

      <Box>
        <input
          type='file'
          ref={fileInputRef}
          onChange={handleAttachFiles}
          style={{ display: 'none' }}
          multiple
          disabled={creatingConversation}
        />
        <Button
          variant='outlined'
          startIcon={<AttachFile />}
          onClick={() => fileInputRef.current?.click()}
          size='small'
          disabled={creatingConversation}
        >
          Attach Files
        </Button>
      </Box>

      {attachments.length > 0 && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {attachments.map((file, index) => (
            <Chip
              key={index}
              label={file.name}
              onDelete={() => handleRemoveAttachment(index)}
              size='small'
            />
          ))}
        </Box>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 1 }}>
        {isDialog && (
          <Button onClick={onClose} size='small'>
            Cancel
          </Button>
        )}
        <Button
          onClick={handleSubmit}
          variant='contained'
          disabled={!topic.trim() || isLoading || creatingConversation}
          size='small'
          endIcon={<Send />}
        >
          Send feedback
        </Button>
      </Box>
    </Box>
  );

  if (isDialog) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
        <Box sx={{ p: 3 }}>{content}</Box>
      </Dialog>
    );
  }

  return (
    <Paper
      variant='outlined'
      elevation={0}
      sx={{
        bottom: 0,
        left: 0,
        right: 0,
        mx: { xs: 2, sm: 4 },
        mb: 2,
        maxWidth: '100%',
        margin: '0 auto',
        p: 2,
        mt: 2,
      }}
    >
      {content}
    </Paper>
  );
}
