import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useAuth } from '../utils/auth/AuthService';
import { sortOptionsAlphabetically } from '../utils/functions/sortOptionsAlphabetically';
import { ConversationTypes } from '../views/SysAdmin/SysAdminModules/UserFeedback/Conversations/conversation.types';
import { useConversation } from '../views/SysAdmin/SysAdminModules/UserFeedback/Conversations/ConversationsContext';
import SimpleAutocompleteCheckbox from './CaseView/SimpleAutocompleteCheckbox';

export function AccountFormDialog({ open, onClose }) {
  const { createConversation } = useConversation();
  const [error, setError] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [phoneNum, setPhoneNum] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [jurisdictionOptions, setJurisdictionOptions] = useState([]);
  const [selectedJurisdictions, setSelectedJurisdictions] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  // general roles based on the form in https://ocsme.nj.gov/cmsaccountrequest, Medexlab-specific roles will be given after this selection
  const [roleOptions, setRoleOptions] = useState([
    { optionSeq: '1', optionName: 'Medical Examiner' },
    { optionSeq: '2', optionName: 'Investigator' },
    { optionSeq: '3', optionName: 'Morgue Technician' },
    { optionSeq: '4', optionName: 'Administration' },
    { optionSeq: '5', optionName: 'Livery' },
  ]);

  const { REACT_APP_API_URL } = process.env;
  const auth = useAuth();
  const location = useLocation;

  const fetchJursidictionOptions = async () => {
    fetch(REACT_APP_API_URL + 'getjurisdictionoptions', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        // only have the 21 NJ jurisdictions + NJ state option
        const filteredOptions = data.filter(
          option =>
            option.optionSeq !== '408A74FB-2DC3-4855-B3D3-25CC7F1853E0' &&
            option.optionSeq !== 'A4842911-4C53-4CEA-931F-0C0CFC548EA6'
        );
        setJurisdictionOptions(sortOptionsAlphabetically(filteredOptions, 'optionName'));
      })
      .catch(e => {
        alert(e);
      });
  };

  useEffect(() => {
    fetchJursidictionOptions();
  }, []);

  const resetForm = () => {
    setFirstName('');
    setLastName('');
    setAddress('');
    setEmail('');
    setPhoneNum('');
    setSelectedJurisdictions('');
    setSelectedRoles('');
    setSubmitting(false);
    setError(null);
  };

  const submitForm = async () => {
    const jurisdictionsList = selectedJurisdictions.map(j => j.optionName).join(', ');
    const rolesList = selectedRoles.map(r => r.optionName).join(', ');

    const body = `
    Employee First Name: ${firstName}
    Employee Last Name: ${lastName}
    Employee Official Email: ${email}
    Residential Address: ${address}
    Mobile Number: ${phoneNum}
    Jurisdictions: ${jurisdictionsList}
    Roles: ${rolesList}`;

    await startConversation(`New account request for ${firstName} ${lastName}`, body);
  };

  const startConversation = async (topic = 'New account request', message = '') => {
    if (!topic.trim()) return;

    try {
      setSubmitting(true);
      setError(null);

      createConversation({
        conversationType: ConversationTypes.New_Account_Request,
        message,
        topic,
      });

      onClose();
    } catch (error) {
      console.error('Error creating conversation:', error);
    } finally {
      setSubmitting(false);
      resetForm();
      setSnackbarOpen(true);
      onClose();
    }
  };

  return (
    <>
      <Dialog open={open} maxWidth='md' fullWidth onClose={onClose}>
        <DialogTitle>Request A New Account</DialogTitle>
        <DialogContent>
          <Box component='form' sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}>
            <TextField
              label='Employee First Name'
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              fullWidth
              sx={{ mt: 1 }}
            />
            <TextField
              label='Employee Last Name'
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              fullWidth
            />
            <TextField
              label='Employee Official Email'
              type='email'
              value={email}
              onChange={e => setEmail(e.target.value)}
              fullWidth
            />
            <TextField
              label='Residential Address'
              value={address}
              onChange={e => setAddress(e.target.value)}
              fullWidth
            />

            <TextField
              label='Mobile Number'
              value={phoneNum}
              onChange={e => setPhoneNum(e.target.value)}
              fullWidth
            />

            <SimpleAutocompleteCheckbox
              multiple
              options={jurisdictionOptions}
              label='Jurisdiction'
              onChange={setSelectedJurisdictions}
            />

            <SimpleAutocompleteCheckbox
              multiple
              options={roleOptions}
              label='Role'
              onChange={setSelectedRoles}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='secondary' variant='outlined'>
            Cancel
          </Button>
          <Button
            onClick={submitForm}
            color='primary'
            variant='contained'
            disabled={
              submitting ||
              !firstName ||
              !lastName ||
              !email ||
              !address ||
              !phoneNum ||
              selectedJurisdictions.length === 0 ||
              selectedRoles.length === 0
            }
          >
            {submitting ? 'Submitting...' : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity='success' sx={{ width: '150%' }}>
          Account Form Submitted Successfully!
        </Alert>
      </Snackbar>
    </>
  );
}
