import { DoneAll } from '@mui/icons-material';
import { Box, Grid, Stack, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { differenceInDays, format, formatDistanceToNow, isSameDay, parseISO } from 'date-fns';
import { useInView } from 'framer-motion';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useAuth } from '../../../../utils/auth/AuthService';
import {
  Attachment,
  ConversationMessage,
  shouldMaskNameAsSupportAgent,
} from './Conversations/conversation.types';
import { ConversationHeader } from './Conversations/ConversationHeader';
import { ConversationList } from './Conversations/ConversationList';
import { ConversationListSkeleton } from './Conversations/ConversationListSkeleton';
import { useConversation } from './Conversations/ConversationsContext';
import { MessageInput } from './Conversations/MessageInput';
import { TypingIndicator } from './Conversations/TypingIndicator';

const { REACT_APP_API_URL } = process.env;

export function UserFeedbackView() {
  const { fetchConversations } = useConversation();
  useEffect(() => {
    fetchConversations({ all: true, conversatonTypeSeqs: [] });
  }, []);
  return (
    <Box sx={{ pt: 1 }}>
      <ConversationLayout kind='sysadmin' />
    </Box>
  );
}
interface ConversationLayoutProps {
  kind: 'user' | 'sysadmin';
  title?: string;
  BoxProps?: {
    sx?: SxProps;
  };
  DefaultComponent?: React.ReactNode;
  CloseButtonComponent?: React.ReactNode;
}

function ConversationLayout({
  title = 'User Feedback',
  kind = 'user',
  BoxProps = {
    sx: {},
  },
  DefaultComponent,
  CloseButtonComponent = <></>,
}: ConversationLayoutProps) {
  const { user } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [attachments, setAttachments] = useState<File[]>([]);
  const [attachmentPreviews, setAttachmentPreviews] = useState<Attachment[]>([]);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const conversationContentRef = useRef<HTMLDivElement>(null);

  const {
    conversations,
    selectedConversation,
    handleSelectConversation,
    showConversationList,
    setShowConversationList,
    setSelectedConversation,
    setConversations,
    loading,
    error,
    supportAgentName,
    handleSendMessage,
    emitTypingIndicator,
    typingParticipants,
    handleMessageRead,
  } = useConversation();

  const sortedConversations = useMemo(() => {
    return [...conversations].sort((a, b) => {
      // Sorting logic
      if (!a.messages?.length || !b.messages?.length) {
        return 0;
      }

      const aLastMessage = a.messages[a.messages.length - 1];
      const bLastMessage = b.messages[b.messages.length - 1];

      if (!aLastMessage?.sentAt || !bLastMessage?.sentAt) {
        return 0;
      }

      try {
        return parseISO(bLastMessage.sentAt).getTime() - parseISO(aLastMessage.sentAt).getTime();
      } catch (error) {
        console.error('Error parsing dates:', error);
        return 0;
      }
    });
  }, [conversations]);

  const handleBackToList = () => {
    setShowConversationList(true);
    setSelectedConversation(null);
  };

  const onSendMessage = async (m: string, e: any) => {
    e?.preventDefault();
    if (!selectedConversation) return;
    await handleSendMessage(selectedConversation.conversationSeq, m, attachments);

    // Clear attachments after sending
    attachmentPreviews.forEach(preview => {
      if (preview.url) {
        URL.revokeObjectURL(preview.url);
      }
    });

    setAttachments([]);
    setAttachmentPreviews([]);
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (selectedConversation?.messages.length) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedConversation?.messages]);

  const isSender = (m: ConversationMessage) =>
    m.senderUserSeq.toLowerCase() === user.userSeq?.toLowerCase();

  if (loading)
    return (
      <Stack>
        <Box
          sx={{
            position: 'absolute',
            top: 8,
            left: 8,
            zIndex: 2,
          }}
        >
          {CloseButtonComponent}
        </Box>
        <Box sx={{ mt: 6 }}>
          <ConversationListSkeleton />
        </Box>
      </Stack>
    );

  const renderMessageArea = () => {
    if (!selectedConversation) return DefaultComponent || null;

    const messageGroups = selectedConversation.messages.reduce((groups, message, index) => {
      const prevMessage = selectedConversation.messages[index - 1];
      const isSameGroup =
        prevMessage &&
        message.senderUserSeq === prevMessage.senderUserSeq &&
        isSameDay(parseISO(message.sentAt), parseISO(prevMessage.sentAt));

      if (isSameGroup) {
        groups[groups.length - 1].push(message);
      } else {
        groups.push([message]);
      }
      return groups;
    }, []);

    return (
      <>
        <ConversationHeader
          onBackClick={handleBackToList}
          isMobile={isMobile}
          supportAgentName={supportAgentName}
        />
        <Box
          sx={{
            flex: 1,
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            minHeight: 0,
          }}
        >
          <Box
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
            // Remove any transition effects that might have been inherited
            style={{ transition: 'none' }}
          >
            {messageGroups.map(group => {
              const firstMessage = group[0];
              const isUserSender = isSender(firstMessage);

              return (
                <Box
                  key={group[0].messageSeq}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: isUserSender ? 'flex-end' : 'flex-start',
                  }}
                >
                  <Typography variant='body2' color='text.secondary' sx={{ mb: 0.5 }}>
                    {shouldMaskNameAsSupportAgent(
                      firstMessage,
                      selectedConversation.conversationType.conversationTypeSeq,
                      user
                    )
                      ? supportAgentName
                      : firstMessage.senderName}
                    {' • '}
                    {formatMessageTime(firstMessage.sentAt)}
                  </Typography>

                  {group.map(message => (
                    <Box
                      key={message.messageSeq}
                      sx={{
                        px: 1.5,
                        py: 1,
                        mb: 0.5,
                        borderRadius: 2.5,
                        bgcolor: isUserSender
                          ? user.isDarkMode
                            ? 'rgb(10, 132, 255)'
                            : 'rgb(0, 122, 255)'
                          : user.isDarkMode
                          ? 'rgb(58, 58, 60)'
                          : 'rgb(229, 229, 234)',
                        color: isUserSender
                          ? 'white'
                          : user.isDarkMode
                          ? 'rgb(229, 229, 234)'
                          : 'black',
                      }}
                    >
                      <MessageContent
                        message={message}
                        onMessageRead={messageSeq => handleMessageRead(messageSeq, user.userSeq)}
                      />
                    </Box>
                  ))}

                  {group === messageGroups[messageGroups.length - 1] &&
                    user.roleCheck(['SYSTEMS-ADMIN']) &&
                    group[group.length - 1].readByCount > 0 && (
                      <Typography
                        variant='body2'
                        color='text.secondary'
                        sx={{
                          fontSize: '0.6rem',
                          display: 'flex',
                          alignItems: 'center',
                          gap: 0.5,
                        }}
                      >
                        <DoneAll sx={{ fontSize: '1rem' }} />
                        {group[group.length - 1].readBy
                          .filter(
                            reader => reader.userSeq.toLowerCase() !== user.userSeq.toLowerCase()
                          )
                          .map(reader => reader.userName)
                          .join(', ')}
                      </Typography>
                    )}
                </Box>
              );
            })}
            <div ref={messagesEndRef} />
          </Box>
          <TypingIndicator
            typingParticipants={typingParticipants[selectedConversation.conversationSeq] || []}
            supportAgentName={supportAgentName}
            conversationTypeSeq={selectedConversation.conversationType.conversationTypeSeq}
            currentUserSeq={user.userSeq}
          />
        </Box>
        <MessageInput
          isConversationClosed={!selectedConversation.isOpen}
          preventEscapeKeyClosing={false}
        />
      </>
    );
  };

  return (
    <Box sx={{ pt: 0, ...BoxProps.sx }}>
      <Grid
        container
        spacing={0}
        sx={{
          display: 'flex',
          overflowY: 'hidden',
          maxHeight: isMobile ? '85vh' : '90vh',
          minHeight: isMobile ? '85vh' : '90vh',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 8,
            left: 8,
            zIndex: 2,
          }}
        >
          {CloseButtonComponent}
        </Box>

        {(showConversationList || !isMobile) && (
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              overflow: 'auto',
              maxHeight: 'inherit',
              // Ensure no transition effects
              transition: 'none',
            }}
          >
            <ConversationList sortedConversations={sortedConversations} title={title} kind={kind} />
          </Grid>
        )}

        {(!showConversationList || !isMobile) && (
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              maxHeight: 'inherit',
              display: 'flex',
              flexDirection: 'column',
              // Ensure no transition effects
              transition: 'none',
            }}
            ref={conversationContentRef}
          >
            {renderMessageArea()}
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

function MessageContent({
  message,
  onMessageRead,
}: {
  message: ConversationMessage;
  onMessageRead?: (messageSeq: string) => void;
}) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.5 });
  const hasTriggeredRead = useRef(false);

  useEffect(() => {
    if (isInView && onMessageRead && !hasTriggeredRead.current) {
      hasTriggeredRead.current = true;
      onMessageRead(message.messageSeq);
    }
  }, [isInView, message.messageSeq, onMessageRead]);

  return (
    <div ref={ref}>
      {message.content && (
        <Typography
          variant='body1'
          sx={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
          }}
        >
          {message.content}
        </Typography>
      )}
    </div>
  );
}

const formatMessageTime = (dateStr: string) => {
  const date = parseISO(dateStr);
  const daysDiff = differenceInDays(new Date(), date);

  // Add check for messages within the last minute
  const diffInSeconds = (new Date().getTime() - date.getTime()) / 1000;
  if (diffInSeconds < 60) {
    return 'now';
  }

  if (daysDiff >= 7) {
    return format(date, 'dd/MM/yyyy hh:mm a');
  }

  const formatted = formatDistanceToNow(date, { addSuffix: true })
    .replace(' seconds', 's')
    .replace(' second', 's')
    .replace(' minutes', 'm')
    .replace(' minute', 'm')
    .replace(' hours', 'h')
    .replace(' hour', 'h')
    .replace(' days', 'd')
    .replace(' day', 'd')
    .replace('about ', '')
    .replace('less than ', '<')
    .replace('almost ', '~');

  return formatted;
};

export default ConversationLayout;
