import { Check, FilterList } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { ConversationType } from './conversation.types';

export function ConversationTypeFilter({
  types,
  selectedTypes,
  onTypeToggle,
}: {
  types: ConversationType[];
  selectedTypes: string[];
  onTypeToggle: (typeSeq: string) => void;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant='text'
        size='small'
        startIcon={<FilterList />}
        onClick={handleClick}
        sx={{ color: selectedTypes.length > 0 ? 'primary.main' : 'text.secondary' }}
      >
        Filter Type {selectedTypes.length > 0 && `(${selectedTypes.length})`}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2, pb: 1 }}>
          <Typography variant='subtitle2' color='text.secondary'>
            Filter by Type
          </Typography>
        </Box>
        <Divider />
        {types.map(type => (
          <MenuItem
            key={type.conversationTypeSeq}
            onClick={() => onTypeToggle(type.conversationTypeSeq)}
            dense
          >
            <ListItemIcon>
              <Checkbox
                edge='start'
                checked={selectedTypes.includes(type.conversationTypeSeq)}
                tabIndex={-1}
                disableRipple
                size='small'
              />
            </ListItemIcon>
            <ListItemText primary={type.typeName} secondary={type.description} />
            {selectedTypes.includes(type.conversationTypeSeq) && (
              <Check fontSize='small' color='primary' />
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
