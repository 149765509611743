// ConversationContext.tsx
import { HubConnectionState } from '@microsoft/signalr';
import { useMediaQuery, useTheme } from '@mui/material';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useAuth } from '../../../../../utils/auth/AuthService';
import { useNotifications } from '../../../../../utils/components/AppBar/Notifications/NotificationsContext';
import {
  Conversation,
  ConversationMessage,
  ConversationParticipant,
  ConversationType,
  ConversationTypes,
} from './conversation.types';

const { REACT_APP_API_URL } = process.env;

interface ConversationContextType {
  conversations: Conversation[];
  setConversations: React.Dispatch<React.SetStateAction<Conversation[]>>;
  loading: boolean;
  refreshing: boolean;
  error: Error | null;
  fetchConversations: ({
    all,
    conversatonTypeSeqs,
  }: {
    all?: boolean;
    conversatonTypeSeqs: string[];
  }) => Promise<void>;
  refreshConversations: () => Promise<void>;
  fetchUserConversations: ({
    userSeq,
    conversationTypeSeqs,
  }: {
    userSeq: string;
    conversationTypeSeqs: string[];
  }) => Promise<void>;
  refreshUserConversations: ({
    userSeq,
    conversationTypeSeqs,
  }: {
    userSeq: string;
    conversationTypeSeqs: string[];
  }) => Promise<void>;
  handleMessageRead: (messageSeq: string, userSeq?: string) => Promise<void>;
  handleSendMessage: (
    conversationSeq: string,
    message: string,
    attachments: File[]
  ) => Promise<void>;
  updateConversationOpenStatus: ({
    conversationSeq,
    isOpen,
  }: {
    conversationSeq: string;
    isOpen: boolean;
  }) => Promise<void>;
  unreadConversationCount: number;
  fetchUnreadConversatonsCount: ({ userSeq }: { userSeq?: string }) => Promise<number>;
  supportAgentName: string | null;
  selectedConversation: Conversation | null;
  setSelectedConversation: React.Dispatch<React.SetStateAction<Conversation>>;
  handleSelectConversation: (conversation: Conversation) => void;
  showConversationList: boolean;
  setShowConversationList: React.Dispatch<React.SetStateAction<boolean>>;
  createConversation: ({
    topic,
    message,
    conversationType,
    participantUserSeqs,
    attachments,
  }: {
    topic?: string;
    message?: string;
    conversationType?: string;
    participantUserSeqs?: string[];
    attachments?: any[];
  }) => Promise<Conversation>;
  creatingConversation: boolean;
  emitTypingIndicator: (conversationSeq: string) => void;
  typingParticipants: Record<string, TypingParticipant[]>;
  conversationTypes: ConversationType[];
}

interface TypingParticipant extends ConversationParticipant {
  isTyping: boolean;
}

const ConversationContext = createContext<ConversationContextType | undefined>(undefined);

export const useConversation = () => {
  const context = useContext(ConversationContext);
  if (!context) {
    throw new Error('useConversation must be used within a ConversationProvider');
  }
  return context;
};

const Endpoints = {
  SysAdmin: `${REACT_APP_API_URL}conversations/all`,
  User: `${REACT_APP_API_URL}getconversations`,
};

export const ConversationProvider = ({ children }: { children: React.ReactNode }) => {
  const { notificationsConnection: connection } = useNotifications();
  const { user } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [unreadConversationCount, setUnreadConversationCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [supportAgentName, setSupportAgentName] = useState<null | string>(null);
  const [selectedConversation, setSelectedConversation] = useState<Conversation | null>(null);
  const [creatingConversation, setCreatingConversation] = useState(false);
  const [showConversationList, setShowConversationList] = useState(false);
  const [conversationTypes, setTypes] = useState<ConversationType[]>([]);

  const [typingParticipants, setTypingParticipants] = useState<Record<string, TypingParticipant[]>>(
    {}
  );
  const typingTimeouts = useRef<Record<string, NodeJS.Timeout>>({});

  useEffect(() => {
    if (connection?.state === HubConnectionState.Connected) {
      conversations.forEach(async conversation => {
        await connection.invoke('JoinConversation', conversation.conversationSeq);
      });

      interface NewMesageEvent {
        conversationSeq: string;
        message: ConversationMessage;
      }
      connection.on('new message', ({ conversationSeq, message }: NewMesageEvent) => {
        if (message.senderUserSeq.toLowerCase() === user.userSeq.toLowerCase()) {
          return;
        }

        const convoToUpdate =
          conversations.find(
            c => c.conversationSeq.toLowerCase() === conversationSeq.toLowerCase()
          ) || null;
        const alreadyHasMessage = convoToUpdate.messages.indexOf(message) > -1;
        if (alreadyHasMessage) {
          return;
        }

        // Existing logic to append the message
        setConversations(prevConversations => {
          const targetConversationIndex = prevConversations.findIndex(
            conv => conv.conversationSeq === conversationSeq
          );

          if (targetConversationIndex === -1) {
            console.warn(`No conversation found with sequence: ${conversationSeq}`);
            return prevConversations;
          }

          const updatedConversations = [...prevConversations];
          const targetConversation = updatedConversations[targetConversationIndex];
          const updatedMessages = [...targetConversation.messages, message];

          updatedConversations[targetConversationIndex] = {
            ...targetConversation,
            messages: updatedMessages,
            unreadCount: calculateUnreadCount(updatedMessages, user.userSeq),
          };

          return updatedConversations;
        });

        if (selectedConversation?.conversationSeq === conversationSeq) {
          handleMessageRead(message.messageSeq);
        }

        const shouldUpdateSelectedConvo =
          selectedConversation?.conversationSeq?.toLowerCase() === conversationSeq?.toLowerCase();

        if (shouldUpdateSelectedConvo) {
          const targetConversationIndex = conversations.findIndex(
            conv => conv.conversationSeq === conversationSeq
          );
          const updatedConversations = [...conversations];
          const targetConversation = updatedConversations[targetConversationIndex];
          const updatedMessages = [...targetConversation.messages, message];

          updatedConversations[targetConversationIndex] = {
            ...targetConversation,
            messages: updatedMessages,
            unreadCount: calculateUnreadCount(updatedMessages, user.userSeq),
          };

          setSelectedConversation(updatedConversations[targetConversationIndex]);
        }

        fetchUnreadConversatonsCount({ userSeq: user.userSeq });
      });

      interface ConversationTypingIndicatorEvent {
        conversationSeq: string;
        userSeq: string;
        isTyping: boolean;
      }

      connection.on('user typing in conversation', ({ conversationSeq, participant, isTyping }) => {
        if (participant.userSeq.toLowerCase() === user.userSeq.toLowerCase()) return;

        setTypingParticipants(prev => {
          const conversationTypers = prev[conversationSeq] || [];

          if (isTyping) {
            const existingIndex = conversationTypers.findIndex(
              p => p.userSeq === participant.userSeq
            );
            if (existingIndex === -1) {
              return {
                ...prev,
                [conversationSeq]: [...conversationTypers, { ...participant, isTyping }],
              };
            }
          } else {
            return {
              ...prev,
              [conversationSeq]: conversationTypers.filter(p => p.userSeq !== participant.userSeq),
            };
          }

          return prev;
        });

        if (isTyping) {
          if (typingTimeouts.current[`${conversationSeq}-${participant.userSeq}`]) {
            clearTimeout(typingTimeouts.current[`${conversationSeq}-${participant.userSeq}`]);
          }

          typingTimeouts.current[`${conversationSeq}-${participant.userSeq}`] = setTimeout(() => {
            setTypingParticipants(prev => ({
              ...prev,
              [conversationSeq]: (prev[conversationSeq] || []).filter(
                p => p.userSeq !== participant.userSeq
              ),
            }));
          }, 3000);
        }
      });

      // Cleanup function
      return () => {
        connection.off('new message');
        connection.off('new conversation');
        connection.off('user typing in conversation');

        // Clear all typing timeouts
        Object.values(typingTimeouts.current).forEach(timeout => clearTimeout(timeout));
      };
    }
  }, [connection, selectedConversation]);

  const emitTypingIndicator = async (conversationSeq: string, isTyping: boolean) => {
    if (connection.state === HubConnectionState.Connected) {
      const participant: ConversationParticipant = {
        userSeq: user.userSeq,
        userName: user.userName,
        personFirstName: user.userFirstName || '',
        personLastName: user.userLastName || '',
        joinedAt: new Date().toISOString(),
      };

      try {
        await connection.invoke('UserTypingInConversation', {
          conversationSeq,
          participant,
          isTyping,
        });
      } catch (error) {
        console.error('Error sending typing indicator:', error);
      }
    }
  };

  const debouncedEmitTyping = useRef<NodeJS.Timeout>();
  const emitTypingIndicatorDebounced = (conversationSeq: string) => {
    if (debouncedEmitTyping.current) {
      clearTimeout(debouncedEmitTyping.current);
    }

    emitTypingIndicator(conversationSeq, true);
    debouncedEmitTyping.current = setTimeout(() => {
      emitTypingIndicator(conversationSeq, false);
    }, 1000);
  };

  const fetchUserConversations = async ({ userSeq = '', conversationTypeSeqs = [] }) => {
    try {
      setSelectedConversation(null);
      setError(null);
      setLoading(true);

      const response = await fetch(Endpoints.User, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify({
          ConversationTypeSeqs: conversationTypeSeqs,
          UserSeq: userSeq,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch conversations');
      }

      const data: Conversation[] = await response.json();
      setConversations(data);
    } catch (error) {
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  const refreshUserConversations = async ({ userSeq = '', conversationTypeSeqs = [] }) => {
    try {
      setError(null);
      setRefreshing(true);

      const response = await fetch(Endpoints.User, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify({
          ConversationTypeSeqs: conversationTypeSeqs,
          UserSeq: userSeq,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch conversations');
      }

      const data: Conversation[] = await response.json();
      setConversations(data);
    } catch (error) {
      setError(error as Error);
    } finally {
      setRefreshing(false);
    }
  };

  const fetchConversations = async ({ all = false, conversationTypeSeqs = [] }) => {
    try {
      setError(null);
      setLoading(true);

      const response = await fetch(Endpoints.SysAdmin, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch conversations');
      }

      const data: Conversation[] = await response.json();
      setConversations(data);
    } catch (error) {
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  const fetchConversationTypes = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}conversation-types`, {
        headers: { Authorization: `Bearer ${user.accessToken}` },
      });
      if (response.ok) {
        const data = await response.json();
        setTypes(data);
      }
    } catch (error) {
      console.error('Error fetching conversation types:', error);
    }
  };
  const fetchConversationsSupportAgentName = async () => {
    try {
      const response = await fetch(`${REACT_APP_API_URL}conversation/support-agent-name`, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch support agent name');
      }

      const data: string = await response.text();
      setSupportAgentName(data);
    } catch (error) {
      console.error('Error fetching support agent name:', error);
    }
  };

  const refreshConversations = async () => {
    try {
      setError(null);
      setRefreshing(true);

      const response = await fetch(Endpoints.SysAdmin, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch conversations');
      }

      const data: Conversation[] = await response.json();
      setConversations(data);
    } catch (error) {
      setError(error as Error);
    } finally {
      setRefreshing(false);
    }
  };

  const fetchUnreadConversatonsCount = async ({ userSeq }) => {
    try {
      const response = await fetch(REACT_APP_API_URL + 'conversations/unread/count', {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        setUnreadConversationCount(data.unreadConversationsCount);
        return data.unreadConversationsCount;
      }

      return 0;
    } catch (error) {
      console.error('Error fetching unread conversations count:', error);
    }
  };

  useEffect(() => {
    fetchConversationTypes();
    fetchConversationsSupportAgentName();
  }, []);

  useEffect(() => {
    if (isMobile) setShowConversationList(true);
  }, [isMobile]);

  // Handle message read
  const pendingReadRequests = useRef<Set<string>>(new Set());

  const createConversation = async ({
    topic = '',
    message = '',
    conversationType = ConversationTypes.Chat,
    participantUserSeqs = [],
    attachments = [],
  }) => {
    if (!topic.trim()) return;

    try {
      setCreatingConversation(true);
      const formData = new FormData();
      formData.append('topic', topic);
      formData.append('initialMessage', message);
      formData.append('conversationTypeSeq', conversationType);

      participantUserSeqs.forEach(userseq => {
        formData.append('participantUserSeqs[]', userseq);
      });

      attachments.forEach(file => {
        formData.append('attachments', file);
      });

      // First create via REST API
      const response = await fetch(`${REACT_APP_API_URL}conversations`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to create conversation');
      }

      const newConversation: Conversation = await response.json();

      setConversations(prevConversations => [newConversation, ...prevConversations]);
      handleSelectConversation(newConversation);

      return newConversation;
    } catch (error) {
      console.error('Error creating conversation:', error);
      throw error;
    } finally {
      setCreatingConversation(false);
    }
  };
  const handleMessageRead = async (messageSeq: string, userSeq?: string) => {
    const conversationContainingMessage = conversations.find(conversation =>
      conversation.messages.some(message => message.messageSeq === messageSeq)
    );

    if (!conversationContainingMessage) return;

    const message = conversationContainingMessage.messages.find(m => m.messageSeq === messageSeq);
    if (!message) return;

    const targetUserSeq = userSeq || user.userSeq;

    if (
      message.readBy.some(r => r.userSeq === targetUserSeq) ||
      pendingReadRequests.current.has(`${messageSeq}-${targetUserSeq}`)
    ) {
      return;
    }

    const optimisticReadBy = [
      ...message.readBy,
      {
        userSeq: targetUserSeq,
        userName: userSeq ? 'Pending...' : user.userLastName,
        personFirstName: userSeq ? '' : user.userFirstName || '',
        personLastName: userSeq ? '' : user.userLastName || '',
        readTimestamp: new Date().toISOString(),
      },
    ];

    const updateConversations = (updatedMessage: ConversationMessage) => {
      setConversations(prevConversations =>
        prevConversations.map(conversation => {
          if (conversation.conversationSeq !== conversationContainingMessage.conversationSeq) {
            return conversation;
          }
          const updatedMessages = conversation.messages.map(m =>
            m.messageSeq === messageSeq
              ? {
                  ...m,
                  readBy: updatedMessage.readBy || optimisticReadBy,
                  readByCount: (updatedMessage.readBy || optimisticReadBy).length,
                }
              : m
          );
          return {
            ...conversation,
            messages: updatedMessages,
            unreadCount: calculateUnreadCount(updatedMessages, user.userSeq),
          };
        })
      );
    };

    // Optimistic update
    updateConversations({ ...message, readBy: optimisticReadBy });

    const pendingKey = `${messageSeq}-${targetUserSeq}`;
    pendingReadRequests.current.add(pendingKey);

    try {
      const response = await fetch(
        `${REACT_APP_API_URL}messages/${messageSeq}/read${userSeq ? `?userSeq=${userSeq}` : ''}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to mark message as read');
      }

      const updatedMessage = await response.json();
      updateConversations(updatedMessage);
    } catch (error) {
      console.error('Error marking message as read:', error);
      // Revert optimistic update
      updateConversations(message);
    } finally {
      pendingReadRequests.current.delete(pendingKey);
    }
  };

  const handleSendMessage = async (
    conversationSeq: string,
    messageContent: string,
    attachments: File[]
  ) => {
    // Create an optimistic message
    const optimisticMessage: ConversationMessage = {
      messageSeq: `temp-${Date.now()}`, // Temporary ID to track this message
      content: messageContent.trim(),
      senderUserSeq: user.userSeq,
      senderName: user.userName,
      sentAt: new Date().toISOString(),
      readBy: [
        {
          userSeq: user.userSeq,
          userName: user.userName,
          personFirstName: user.userFirstName || '',
          personLastName: user.userLastName || '',
          readTimestamp: new Date().toISOString(),
        },
      ],
      readByCount: 1,
      attachments: [],
    };

    // Optimistically update the UI
    const updateConversationsOptimistically = () => {
      setConversations(prevConversations =>
        prevConversations.map(conv => {
          if (conv.conversationSeq === conversationSeq) {
            return {
              ...conv,
              messages: [...conv.messages, optimisticMessage],
            };
          }
          return conv;
        })
      );

      // Update selectedConversation if the message belongs to it
      if (selectedConversation?.conversationSeq === conversationSeq) {
        setSelectedConversation(prevSelected => ({
          ...prevSelected!,
          messages: [...prevSelected!.messages, optimisticMessage],
        }));
      }
    };

    // Apply optimistic update
    updateConversationsOptimistically();

    try {
      const formData = new FormData();
      formData.append('content', messageContent.trim());
      attachments.forEach(file => {
        formData.append('attachments', file);
      });

      // Send the actual request
      const response = await fetch(
        `${REACT_APP_API_URL}conversations/${conversationSeq}/messages`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error('Failed to send message');
      }

      const actualMessage: ConversationMessage = await response.json();

      // Broadcast via SignalR if connected
      if (connection.state === HubConnectionState.Connected) {
        connection.invoke('BroadcastNewMessage', conversationSeq, actualMessage);
      }

      // Update the conversations with the actual message
      setConversations(prevConversations =>
        prevConversations.map(conv => {
          if (conv.conversationSeq === conversationSeq) {
            return {
              ...conv,
              messages: conv.messages.map(msg =>
                msg.messageSeq === optimisticMessage.messageSeq ? actualMessage : msg
              ),
            };
          }
          return conv;
        })
      );

      // Update selectedConversation if needed
      if (selectedConversation?.conversationSeq === conversationSeq) {
        setSelectedConversation(prevSelected => ({
          ...prevSelected!,
          messages: prevSelected!.messages.map(msg =>
            msg.messageSeq === optimisticMessage.messageSeq ? actualMessage : msg
          ),
        }));
      }

      // Clean up any temporary URLs we created
      optimisticMessage.attachments?.forEach(attachment => {
        if (attachment.url.startsWith('blob:')) {
          URL.revokeObjectURL(attachment.url);
        }
      });
    } catch (error) {
      console.error('Error sending message:', error);

      // Remove the optimistic message on error
      setConversations(prevConversations =>
        prevConversations.map(conv => {
          if (conv.conversationSeq === conversationSeq) {
            return {
              ...conv,
              messages: conv.messages.filter(
                msg => msg.messageSeq !== optimisticMessage.messageSeq
              ),
            };
          }
          return conv;
        })
      );

      // Update selectedConversation if needed
      if (selectedConversation?.conversationSeq === conversationSeq) {
        setSelectedConversation(prevSelected => ({
          ...prevSelected!,
          messages: prevSelected!.messages.filter(
            msg => msg.messageSeq !== optimisticMessage.messageSeq
          ),
        }));
      }

      // Clean up any temporary URLs we created
      optimisticMessage.attachments?.forEach(attachment => {
        if (attachment.url.startsWith('blob:')) {
          URL.revokeObjectURL(attachment.url);
        }
      });

      // You might want to show an error message to the user here
      throw error;
    }
  };

  const handleSelectConversation = (conversation: Conversation) => {
    setSelectedConversation(conversation);
    if (isMobile) {
      setShowConversationList(false);
    }
  };

  const calculateUnreadCount = (messages: ConversationMessage[], userSeq: string): number => {
    return messages.filter(
      message =>
        !message.readBy.some(reader => reader.userSeq.toLowerCase() === userSeq.toLowerCase())
    ).length;
  };

  const updateConversationOpenStatus = async ({ conversationSeq = '', isOpen = false }) => {
    try {
      const response = await fetch(`${REACT_APP_API_URL}conversations/${conversationSeq}/status`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          isOpen: isOpen,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update conversation status');
      }

      const updatedConversation = await response.json();

      setConversations(prevConversations =>
        prevConversations.map(conv =>
          conv.conversationSeq === conversationSeq ? updatedConversation : conv
        )
      );

      // Update selectedConversation if it matches
      setSelectedConversation(prevSelected =>
        prevSelected?.conversationSeq === conversationSeq ? updatedConversation : prevSelected
      );
    } catch (error) {
      console.error('Error updating conversation status:', error);
    }
  };
  return (
    <ConversationContext.Provider
      value={{
        fetchUserConversations,
        refreshUserConversations,
        conversations,
        setConversations,
        unreadConversationCount,
        fetchUnreadConversatonsCount,
        loading,
        refreshing,
        refreshConversations,
        error,
        updateConversationOpenStatus,
        fetchConversations,
        handleMessageRead,
        handleSendMessage,
        supportAgentName,
        selectedConversation,
        setSelectedConversation,
        showConversationList,
        setShowConversationList,
        handleSelectConversation,
        createConversation,
        creatingConversation,
        emitTypingIndicator: emitTypingIndicatorDebounced,
        typingParticipants,
        conversationTypes,
      }}
    >
      {children}
    </ConversationContext.Provider>
  );
};
